import { useEffect, useState, useCallback } from 'react';

import { Stack } from '@chakra-ui/react';

import UsersTable from '../components/UsersTable';
import UserDetails from '../components/UserDetails';
import Paginator from '../components/Paginator';

import { getUsers, getSubscriptions, getBouquets } from '../api';

function Users() {
  const [currentPage, setCurrentPage] = useState(0);
  const [usersData, setUsersData] = useState({ users: [], totalUsers: 0 });
  const [subscriptions, setSubscriptions] = useState([]);
  const [bouquets, setBouquets] = useState([]);

  useEffect(_ => {
    getSubscriptions().then(setSubscriptions)
  }, []);
  useEffect(_ => {
    getBouquets().then(setBouquets)
  }, []);

  const refresh = useCallback(page => {
    getUsers(page || currentPage).then(setUsersData)
  }, [currentPage, setUsersData]);
  useEffect(_ => {
    refresh(currentPage)
  }, [refresh, currentPage]);

  return (
    <Stack spacing="4">
      <UserDetails
        refresh={refresh}
        isCreate={true}
        subscriptions={subscriptions}
        bouquets={bouquets}
      />
      <UsersTable
        users={usersData.users}
        refresh={refresh}
        subscriptions={subscriptions}
        bouquets={bouquets}
      />
      <Paginator
        totalItems={usersData.totalUsers}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Stack>
  );
}

export default Users;
