import moment from 'moment'

export const login = async (username, password) => {
  const r = await fetch('/api/login', {
    method: 'POST',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ username, password })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const logout = async _ => {
  await fetch('/api/logout')
}

export const getUser = async _ => {
  const r = await fetch('/api/me')
  if (r.status !== 200) return {}
  return await r.json()
}

export const getMetrics = async _ => {
  const r = await fetch('/api/metrics')
  if (r.status !== 200) return {}
  return await r.json()
}

export const getStatistics = async _ => {
  const r = await fetch('/api/statistics')
  if (r.status !== 200) return {}
  return await r.json()
}

export const getResellers = async page => {
  const r = await fetch('/api/resellers?page=' + page)
  if (r.status !== 200) return []
  return await r.json()
}

export const getUsers = async _ => {
  const r = await fetch('/api/users')
  if (r.status !== 200) return []
  return await r.json()
}

export const getCategories = async _ => {
  const r = await fetch('/api/categories')
  if (r.status !== 200) return []
  return await r.json()
}

export const deleteCategory = async category => {
  const r = await fetch('/api/category', {
    method: 'DELETE',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(category)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const createCategory = async category => {
  const r = await fetch('/api/category', {
    method: 'PUT',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ name: category })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const getSubscriptions = async _ => {
  const r = await fetch('/api/packets')
  if (r.status !== 200) return []
  return await r.json()
}

export const deleteSubscription = async subscription => {
  const r = await fetch('/api/packet', {
    method: 'DELETE',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(subscription)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const createSubscription = async subscription => {
  const r = await fetch('/api/packet', {
    method: 'PUT',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(subscription)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const saveSubscription = async subscription => {
  const r = await fetch('/api/packet', {
    method: 'POST',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(subscription)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const getBouquets = async _ => {
  const r = await fetch('/api/bouquets')
  if (r.status !== 200) return []
  return await r.json()
}

export const getChannels = async page => {
  const r = await fetch('/api/channels?page=' + page)
  if (r.status !== 200) return []
  return await r.json()
}

export const getBouquetChannels = async bouquet => {
  const r = await fetch('/api/bouquet/' + bouquet)
  if (r.status !== 200) return []
  return await r.json()
}

export const saveChannel = async channel => {
  const r = await fetch('/api/saveChannel', {
    method: 'POST',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(channel)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const createChannel = async channel => {
  const r = await fetch('/api/saveChannel', {
    method: 'PUT',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(channel)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const deleteChannel = async channel => {
  const r = await fetch('/api/channel', {
    method: 'DELETE',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(channel)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const searchChannels = async query => {
  const r = await fetch('/api/channel/search?q=' + encodeURIComponent(query))
  if (r.status !== 200) return []
  return await r.json()
}

export const addChannelToBouquet = async (bouquetID, channel) => {
  const r = await fetch('/api/bouquet/' + bouquetID + '/add/' + channel)
  if (r.status !== 200) return []
  return await r.json()
}

export const saveReseller = async reseller => {
  const r = await fetch('/api/reseller', {
    method: 'POST',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ ...reseller, expiration: reseller.expiration ? moment(reseller.expiration).format('YYYY-MM-DDT00:00:00Z') : null })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const createReseller = async reseller => {
  const r = await fetch('/api/reseller', {
    method: 'PUT',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ ...reseller, expiration: reseller.expiration ? moment(reseller.expiration).format('YYYY-MM-DDT00:00:00Z') : null })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const deleteReseller = async reseller => {
  const r = await fetch('/api/reseller', {
    method: 'DELETE',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(reseller)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const getSearchTmdb = async query => {
  const qs = new URLSearchParams()
  qs.set('q', query)
  const r = await fetch(`/api/tmdb?${qs.toString()}`)
  if (r.status !== 200) return []
  return await r.json()
}

export const getTmdbGenres = async _ => {
  const r = await fetch(`/api/genres`)
  if (r.status !== 200) return []
  return await r.json()
}

export const saveUser = async user => {
  const r = await fetch('/api/user', {
    method: 'POST',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ ...user, expiration: user.expiration ? moment(user.expiration).format('YYYY-MM-DDT00:00:00Z') : null })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const createUser = async user => {
  const r = await fetch('/api/user', {
    method: 'PUT',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify({ ...user, expiration: user.expiration ? moment(user.expiration).format('YYYY-MM-DDT00:00:00Z') : null })
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const deleteUser = async reseller => {
  const r = await fetch('/api/user', {
    method: 'DELETE',
    headers: {
      contentType: 'application/json'
    },
    body: JSON.stringify(reseller)
  })
  if (r.status !== 200) return false
  return await r.json()
}

export const getEPG = async _ => {
  const r = await fetch(`/api/epg`)
  if (r.status !== 200) return []
  return await r.json()
}

export const getEPGLangs = async _ => {
  const r = await fetch(`/api/epgLangs`)
  if (r.status !== 200) return []
  return await r.json()
}
