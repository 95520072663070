import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Grid,
  GridItem,
  Input,
  InputLeftAddon,
  InputRightAddon,
  InputGroup,
  ButtonGroup,
  Textarea,
  Avatar,
  Text,
  Badge,
  Spacer,
  IconButton,
  useToast
} from '@chakra-ui/react'

import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag
} from '@choc-ui/chakra-autocomplete'

import { useEffect, useRef, useState } from 'react'
import { 
  FaExternalLinkAlt,
  FaClipboardList,
  FaPlus,
  FaSave,
  FaTimes
} from 'react-icons/fa'

import {
  createChannel,
  getBouquets,
  getCategories,
  getEPG,
  getEPGLangs,
  getSearchTmdb,
  getTmdbGenres,
  saveChannel
} from '../api'

const getChannelModel = _ => ({
  bouquets: [],
  category_id: 0,
  epg: {
      id: '',
      lang: ''
  },
  live: true,
  logo: '',
  source: '',
  title: '',
  tmdb: {
      adult: false,
      backdrop: '',
      country: '',
      genre: '',
      id: 0,
      plot: '',
      release_date: '',
      title: '',
      type: ''
  }
})

const ChannelDetails = ({ inputChannel, refresh, isCreate }) => {
  const toast = useToast()
  const [channel, setChannel] = useState(inputChannel || getChannelModel())
  const [categories, setCategories] = useState([])
  const [bouquets, setBouquets] = useState([])
  const [tmdbquery, setTmdbQuery] = useState('')
  const [tmdbsearch, setTmdbSearch] = useState([])
  const [genres, setGenres] = useState([])
  const [epg, setEPG] = useState([])
  const [epgLangs, setEPGLangs] = useState([])
  
  const logoStyle = {
    backgroundImage: `url(${channel.logo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  }
  const backdropStyle = {
    backgroundImage: `url(${channel.tmdb.backdrop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    height: '180px'
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const openModal = _ => {
    getCategories().then(setCategories)
    getBouquets().then(setBouquets)
    getTmdbGenres().then(setGenres)
    getEPG().then(setEPG)
    getEPGLangs().then(setEPGLangs)
    onOpen()
  }

  const closeModal = _ => {
    setChannel(inputChannel || getChannelModel())
    onClose()
  }

  const updateCategory = newCategoryName => {
    const newCategory = categories.find(c => c.name === newCategoryName)
    if (!newCategory) return
    setChannel({ ...channel, category: newCategory, category_id: newCategory.category_id })
  }

  const updateBouquets = newBouquetNames => {
    const newBouquets = bouquets.filter(b => newBouquetNames.includes(b.name))
    setChannel({ ...channel, bouquets: newBouquets })
  }

  let timeout = useRef()
  const debouncedSearch = _ => {
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }
    timeout.current = setTimeout(_ => {
      if (tmdbquery.length < 3) return
      getSearchTmdb(tmdbquery).then(r => setTmdbSearch(r.results))
    }, 500)
  }
  const searchTmdb = query => setTmdbQuery(query)
  useEffect(debouncedSearch, [tmdbquery])

  const handleTmdbSelect = selected => {
    const tmdbid = parseInt(selected.item.value, 10)
    const selectedItem = tmdbsearch.find(x => x.id === tmdbid)
    const relDate = selectedItem.release_date || selectedItem.first_air_date
    let title = selectedItem.title || selectedItem.name
    if (relDate.length > 0) title += ` (${relDate.substr(0, 4)})`
    const genre = genres.find(g => g.id === selectedItem.genre_ids[0])
    setChannel({
      ...channel,
      title: title,
      logo: `https://image.tmdb.org/t/p/original/${selectedItem.poster_path}`,
      tmdb: {
        id: tmdbid,
        type: selectedItem.media_type,
        backdrop: `https://image.tmdb.org/t/p/original/${selectedItem.backdrop_path}`,
        country: selectedItem.origin_country && selectedItem.origin_country[0],
        genre: genre && genre.name,
        plot: selectedItem.overview,
        release_date: relDate,
        title: selectedItem.title || selectedItem.name
      }
    })
  }

  const save = async _ => {
    if (isCreate) {
      if (channel.title.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Title is required',
          status: 'error'
        })
        return
      }
      if (channel.source.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Source is required',
          status: 'error'
        })
        return
      }
      if (!channel.category) {
        toast({
          title: 'Save failed',
          description: 'Category is required',
          status: 'error'
        })
        return
      }
      await createChannel(channel)
    } else {
      await saveChannel(channel)
    }
    toast({
      title: 'Save success',
      description: `${channel.title} saved successfully!`,
      status: 'success'
    })
    refresh()
    onClose()
  }

  return <>
    <Button onClick={openModal}
      size={isCreate ? 'md' : 'sm'}
      colorScheme={isCreate ? 'green' : 'blue'}
      leftIcon={isCreate ? <FaPlus /> : <FaClipboardList />}
      style={{ width: 'fit-content' }}>
        {isCreate ? 'Create' : 'Details'}
    </Button>
    <Modal isOpen={isOpen} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent style={{maxWidth:'1024px'}}>
        <ModalHeader>Details: {channel.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns='180px repeat(2, 1fr)' gap={2}>
            <GridItem rowSpan={5} style={logoStyle} mr='2' />
            <GridItem colSpan={2}>
              <InputGroup>
                <InputLeftAddon w='150px' children='Title' />
                <Input placeholder='Title' value={channel.title} onChange={e => setChannel({ ...channel, title: e.target.value })} />
              </InputGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <InputGroup>
                <InputLeftAddon w='150px' children='Source' />
                <Input placeholder='Source' value={channel.source} onChange={e => setChannel({ ...channel, source: e.target.value })} />
              </InputGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <InputGroup>
                <InputLeftAddon w='150px' children='Logo' />
                <Input placeholder='Logo' value={channel.logo} onChange={e => setChannel({ ...channel, logo: e.target.value })} />
              </InputGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <InputGroup>
                <InputLeftAddon w='150px' children='Category' />
                <AutoComplete openOnFocus defaultValue={channel.category && channel.category.name} onChange={updateCategory}>
                  <AutoCompleteInput />
                  <AutoCompleteList>
                    {
                      categories.map((c, x) => <AutoCompleteItem
                        key={x} value={c.name}>
                          {c.name}
                        </AutoCompleteItem>)
                    }
                  </AutoCompleteList>
                </AutoComplete>
              </InputGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <InputGroup>
                <InputLeftAddon w='150px' children='Bouquets' />
                <AutoComplete openOnFocus multiple onChange={updateBouquets} defaultValues={channel.bouquets.map(b => b.name)}>
                  <AutoCompleteInput>
                    {
                      ({ tags }) => tags.map((tag, tid) => <AutoCompleteTag key={tid} label={tag.label} onRemove={tag.onRemove} />)
                    }
                  </AutoCompleteInput>
                  <AutoCompleteList>
                    {bouquets.map((bouquet, x) => (
                      <AutoCompleteItem key={x} value={bouquet.name} _selected={{ bg: 'whiteAlpha.50' }} _focus={{ bg: 'whiteAlpha.100' }}>
                        {bouquet.name}
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </AutoComplete>
              </InputGroup>
            </GridItem>
            { /* ############### */ }
            <GridItem colSpan={3}>
              <InputGroup>
                <ButtonGroup isAttached>
                  <Button colorScheme={channel.live ? 'blue' : 'gray'} onClick={e => setChannel({ ...channel, live: true })}>Live</Button>
                  <Button colorScheme={channel.live ? 'gray' : 'blue'} onClick={e => setChannel({ ...channel, live: false })}>VOD</Button>
                </ButtonGroup>
              </InputGroup>
            </GridItem>
            {
              channel.live
              ? <>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='EPG ID' />
                      <AutoComplete openOnFocus creatable defaultValue={channel.epg.id} onSelectOption={e => setChannel({ ...channel, epg: { ...channel.epg, id: e.item.value } })} >
                        <AutoCompleteInput placeholder='EPG ID' variant="filled" />
                        <AutoCompleteList>
                          {epg.map((ch, i) => (
                            <AutoCompleteItem key={`epg-${i}`} value={ch} >
                              {ch}
                            </AutoCompleteItem>
                          ))}
                          <AutoCompleteCreatable />
                        </AutoCompleteList>
                      </AutoComplete>
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='EPG Lang' />
                      <AutoComplete openOnFocus creatable defaultValue={channel.epg.lang} onSelectOption={e => setChannel({ ...channel, epg: { ...channel.epg, lang: e.item.value } })} >
                        <AutoCompleteInput placeholder='EPG Lang' variant="filled" />
                        <AutoCompleteList>
                          {epgLangs.map((lang, i) => (
                            <AutoCompleteItem key={`epgLang-${i}`} value={lang} >
                              {lang}
                            </AutoCompleteItem>
                          ))}
                          <AutoCompleteCreatable />
                        </AutoCompleteList>
                      </AutoComplete>
                    </InputGroup>
                  </GridItem>
                </>
              : <>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='TMDb Search' />
                      <AutoComplete openOnFocus onSelectOption={handleTmdbSelect} defaultValue={`${channel.tmdb.title} (${channel.tmdb.id})`}>
                        <AutoCompleteInput placeholder='Search...' onChange={e => searchTmdb(e.target.value)} />
                        <AutoCompleteList>
                          {tmdbsearch.filter(r => ['tv', 'movie'].includes(r.media_type)).map((result, i) => (
                            <AutoCompleteItem
                              key={i}
                              value={`${result.id}`}
                              label={`${result.name || result.title} (${result.id})`}
                              align='center'
                            >
                              <Avatar src={`https://image.tmdb.org/t/p/w200/${result.poster_path}`} />
                              <Text ml='4'>{result.name || result.title} <small>({result.id})</small></Text>
                              <Spacer />
                              <Badge colorScheme={result.media_type === 'tv' ? 'blue' : 'orange'}>{result.media_type}</Badge>
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                      <InputRightAddon>
                          <IconButton
                            variant='ghost' icon={<FaExternalLinkAlt />}
                            onClick={_ => window.open(`https://www.themoviedb.org/${channel.tmdb.type}/${channel.tmdb.id}`, '_blank')}
                          />
                      </InputRightAddon>
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='Genre' />
                      <Input placeholder='Genre' isReadOnly readOnly value={channel.tmdb.genre} />
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='ReleaseDate' />
                      <Input placeholder='Release Date' isReadOnly readOnly value={channel.tmdb.release_date} />
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='Country' />
                      <Input placeholder='Country' isReadOnly readOnly value={channel.tmdb.country} />
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <Textarea placeholder='Plot' isReadOnly readOnly value={channel.tmdb.plot}></Textarea>
                    </InputGroup>
                  </GridItem>
                  <GridItem colSpan={3}>
                    <InputGroup>
                      <InputLeftAddon w='150px' children='Backdrop' />
                      <Input placeholder='Backdrop' isReadOnly readOnly value={channel.tmdb.backdrop} />
                    </InputGroup>
                  </GridItem>
                  {
                    channel.tmdb.backdrop && 
                    <GridItem colSpan={3} style={backdropStyle}></GridItem>
                  }
                </>
            }
          </Grid>            
        </ModalBody>
        <ModalFooter>
          { !isCreate && <Badge>#{channel.channel_id}</Badge>}
          <Spacer />
          <ButtonGroup>
            <Button leftIcon={<FaTimes />} mr={1} onClick={closeModal}>Close</Button>
            <Button rightIcon={<FaSave />} colorScheme='green' onClick={save}>Save</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export default ChannelDetails
