import Stats from '../components/Stats'

import {
  Text
} from '@chakra-ui/react'

import {
  FaBrain,
  FaDatabase
} from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { getStatistics } from '../api';

function Dashboard () {
  // TODO: GET USER PERMISSIONS AND SHOW/HIDE STATS
  const [statistics, setStatistics] = useState({})
  useEffect(_ => {
    getStatistics().then(setStatistics)
  }, [])

  return <>
    <Text fontSize='xl' fontWeight='bold' mt={4}>Stream Engine</Text>
    <Stats />
    { /* TODO: CURRENT USER INFO */ }
    { /* TODO: LIST AVAILABLE PLAYLISTS/FORMATS/BOUQUETS */ }
    <Text fontSize='md' fontWeight='bold' mt={4}>System</Text>
    <Text fontSize='sm' color='gray'>
      <FaBrain style={{ display: 'inline-block' }} /> <strong>Engine:</strong> {statistics.engine}
    </Text>
    <Text fontSize='sm' color='gray'>
      <FaDatabase style={{ display: 'inline-block' }} /> <strong>Database:</strong> {statistics.database}
    </Text>
  </>
}

export default Dashboard
