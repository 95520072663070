import { useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Button, ButtonGroup, InputGroup, InputLeftElement, InputRightElement, Input } from '@chakra-ui/react'
import {
  FaSave,
  FaTimes,
  FaPlus,
  FaSearch
} from 'react-icons/fa'

import CategoryDelete from './CategoryDelete'

import { createCategory } from '../api'

function CategoriesTable({ categories, refresh }) {
  const [isAdd, setAdd] = useState(false)
  const [newCategory, setNewCategory] = useState('')
  const [search, setSearch] = useState('')

  const save = async _ => {
    if (newCategory.length > 0) {
      await createCategory(newCategory)
      setNewCategory('')
      setAdd(false)
      refresh()
    }
  }

  const add = _ => setAdd(true)

  const cancelAdd = _ => {
    setAdd(false)
    setNewCategory('')
  }

  return <Table variant='unstyled' size='sm'>
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Name</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td></Td>
        <Td>
          <InputGroup>
            <InputLeftElement pointerEvents='none' children={<FaSearch />} />
            <Input placeholder='Search...' variant='filled' value={search} onChange={e => setSearch(e.target.value)} />
            <InputRightElement>
              <Button size='sm' disabled={search.length === 0} onClick={_ => setSearch('')}><FaTimes /></Button>
            </InputRightElement>
          </InputGroup>
        </Td>
        <Td></Td>
      </Tr>
      {
        (search.length > 0 ? categories.filter(c => c.name.toLocaleLowerCase().indexOf(search) > -1) : categories).map(category => {
          return <Tr key={category.category_id}>
            <Td>{category.category_id}</Td>
            <Td>{category.name}</Td>
            <Td>
              <CategoryDelete category={category} refresh={refresh} />
            </Td>
          </Tr>
        })
      }
      <Tr>
        {
          isAdd
          ? <>
            <Td></Td>
            <Td>
              <Input size='sm' variant='filled' value={newCategory} onChange={e => setNewCategory(e.target.value)} />
            </Td>
            <Td>
              <ButtonGroup isAttached>
                <Button size='sm' colorScheme='green' onClick={save}><FaSave /></Button>
                <Button size='sm' colorScheme='red' onClick={cancelAdd}><FaTimes /></Button>
              </ButtonGroup>
            </Td>
          </>
          : <>
            <Td></Td>
            <Td></Td>
            <Td>
              <Button size='sm' onClick={add}><FaPlus /></Button>
            </Td>
          </>
        }
      </Tr>
    </Tbody>
  </Table>
}

export default CategoriesTable
