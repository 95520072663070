import { useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, Input, InputGroup, InputLeftElement, InputRightElement, Button, ButtonGroup } from '@chakra-ui/react'

import {
  FaSearch,
  FaSave,
  FaTimes,
  FaPlus
} from 'react-icons/fa'

import SubscriptionDelete from './SubscriptionDelete'
import SubscriptionDetails from './SubscriptionDetails'

import { createSubscription } from '../api'

function SubscriptionsTable({ subscriptions, refresh }) {
  const [isAdd, setAdd] = useState(false)
  const [newSubscription, setNewSubscription] = useState('')
  const [newSubscriptionDuration, setNewSubscriptionDuration] = useState(0)
  const [search, setSearch] = useState('')

  const save = async _ => {
    if (newSubscription.length > 0) {
      await createSubscription({
        name: newSubscription,
        duration: 1 * newSubscriptionDuration
      })
      setNewSubscription('')
      setNewSubscriptionDuration(0)
      setAdd(false)
      refresh()
    }
  }

  const add = _ => setAdd(true)

  const cancelAdd = _ => {
    setAdd(false)
    setNewSubscription('')
    setNewSubscriptionDuration(0)
  }

  return <Table variant='unstiled' size='sm'>
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>Name</Th>
        <Th>Duration</Th>
        <Th>&nbsp;</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td></Td>
        <Td>
          <InputGroup>
            <InputLeftElement pointerEvents='none' children={<FaSearch />} />
            <Input placeholder='Search...' variant='filled' value={search} onChange={e => setSearch(e.target.value)} />
            <InputRightElement>
              <Button size='sm' disabled={search.length === 0} onClick={_ => setSearch('')}><FaTimes /></Button>
            </InputRightElement>
          </InputGroup>
        </Td>
        <Td></Td>
        <Td></Td>
      </Tr>
      {
        (search.length > 0 ? subscriptions.filter(c => c.name.toLocaleLowerCase().indexOf(search) > -1) : subscriptions).map(subscription => {
          return <Tr key={subscription.packet_id}>
            <Td>{subscription.packet_id}</Td>
            <Td>{subscription.name}</Td>
            <Td>{subscription.duration}</Td>
            <Td>
              <ButtonGroup isAttached>
                <SubscriptionDetails initialSubscription={subscription} refresh={refresh} />
                <SubscriptionDelete subscription={subscription} refresh={refresh} />
              </ButtonGroup>
            </Td>
          </Tr>
        })
      }
      <Tr>
        {
          isAdd
          ? <>
            <Td></Td>
            <Td>
              <Input size='sm' variant='filled' value={newSubscription} onChange={e => setNewSubscription(e.target.value)} />
            </Td>
            <Td>
              <Input type='number' size='sm' variant='filled' value={newSubscriptionDuration} onChange={e => setNewSubscriptionDuration(e.target.value)} />
            </Td>
            <Td>
              <ButtonGroup isAttached>
                <Button size='sm' colorScheme='green' onClick={save}><FaSave /></Button>
                <Button size='sm' colorScheme='red' onClick={cancelAdd}><FaTimes /></Button>
              </ButtonGroup>
            </Td>
          </>
          : <>
            <Td></Td>
            <Td></Td>
            <Td></Td>
            <Td>
              <Button size='sm' onClick={add}><FaPlus /></Button>
            </Td>
          </>
        }
      </Tr>
    </Tbody>
  </Table>
}

export default SubscriptionsTable
