import React, { useEffect } from 'react';
import { Flex, Box, Grid, useColorModeValue } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import Nav from './components/Nav';

import Login from './views/Login';
import Resellers from './views/Resellers';
import Users from './views/Users';
import Channels from './views/Channels';
import Bouquets from './views/Bouquets';
import Subscriptions from './views/Subscriptions';
import Dashboard from './views/Dashboard';
import Categories from './views/Categories';

import userState from './userState';
import { getUser } from './api';
import Logout from './views/Logout';

function App() {
  const [user, setUser] = useRecoilState(userState);
  const bg = useColorModeValue('gray.200', 'gray.700');
  useEffect(_ => {
    getUser().then(u => setUser(u))
  }, [setUser]);

  return (
    <Grid minH="100vh">
      <Flex
        flexDirection="column"
        width="100vw"
        bg={bg}
        align="center"
      >
        <Nav/>
        {!user || !user.user_id ? (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        ) : (
          <Box p="2.5em" maxW="1400px" w="100%" paddingTop='90px'>
            <Routes>
              {user.user_type >= 1 && (
                <Route path="/users" element={<Users />} />
              )}
              {user.user_type >= 2 && (
                <Route path="/resellers" element={<Resellers />} />
              )}
              {user.user_type >= 3 && (
                <Route path="/channels" element={<Channels />} />
              )}
              {user.user_type >= 3 && (
                <Route path="/bouquets" element={<Bouquets />} />
              )}
              {user.user_type >= 3 && (
                <Route path="/subscriptions" element={<Subscriptions />} />
              )}
              {user.user_type >= 3 && (
                <Route path="/categories" element={<Categories />} />
              )}
              <Route path="/logout" element={<Logout />} />
              <Route path="/" element={<Dashboard />} />
            </Routes>
          </Box>
        )}
      </Flex>
    </Grid>
  );
}

export default App;
