import { useEffect, useState } from 'react'
import { 
  Stack
} from '@chakra-ui/react'

import SubscriptionsTable from '../components/SubscriptionsTable' 

import { getSubscriptions } from '../api'

function Subscriptions () {
  const [subscriptions, setSubscriptions] = useState([])

  const refresh = _ => getSubscriptions().then(setSubscriptions)
  useEffect(_ => {
    refresh()
  }, [])

  return <Stack spacing='4'>
    <SubscriptionsTable subscriptions={subscriptions} refresh={refresh} />
  </Stack>
}

export default Subscriptions
