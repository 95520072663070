import {
  Flex,
  Button,
  IconButton,
  Spacer,
  Stack,
  useColorModeValue,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  Text,
  Box
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import {
  FaSignOutAlt,
  FaUsers,
  FaTv,
  FaBoxes,
  FaList,
  FaUserShield,
  FaChartBar,
  FaBars,
  FaTh,
  FaCoins
} from 'react-icons/fa'
import { useRecoilState } from 'recoil'

import { ColorModeSwitcher } from './ColorModeSwitcher'

import userState from '../userState'
import { useLocation } from 'react-router-dom'

function Nav() {
  const user = useRecoilState(userState)[0]
  const location = useLocation()
  const bg = useColorModeValue('gray.300', 'gray.800')
  const [isMobile] = useMediaQuery('(max-width: 1250px)')

  if (!user || !user.user_id) return null

  const menuButtons = [
    {
      title: 'Dashboard',
      link: '/',
      icon: <FaChartBar />,
      min_user_type: 0
    },
    {
      title: 'Resellers',
      link: '/resellers',
      icon: <FaUserShield />,
      min_user_type: 2
    },
    {
      title: 'Users',
      link: '/users',
      icon: <FaUsers />,
      min_user_type: 1
    },
    {
      title: 'Channels',
      link: '/channels',
      icon: <FaTv />,
      min_user_type: 3
    },
    {
      title: 'Categories',
      link: '/categories',
      icon: <FaTh />,
      min_user_type: 3
    },
    {
      title: 'Bouquets',
      link: '/bouquets',
      icon: <FaList />,
      min_user_type: 3
    },
    {
      title: 'Subscriptions',
      link: '/subscriptions',
      icon: <FaBoxes />,
      min_user_type: 3
    },
  ]

  const userAvatar = <Flex>
    <Avatar src={`https://avatars.dicebear.com/api/adventurer-neutral/${user.username}.svg`} alt={user.username} />
    <Box ml='3'>
      <Text fontWeight='bold'>
        {user.username}
      </Text>
      <Text fontSize='sm'>
        <FaCoins style={{ display: 'inline-block' }} /> {user.credits}
      </Text>
    </Box>
  </Flex>

  if (isMobile) {
    return <Flex align='center' p='.5em' bg={bg} w='100%' style={{position: 'fixed', top: 0, width: '100%', zIndex: '99999'}}>
      <Stack direction='row'>
        <Menu>
          <MenuButton as={Button} variant='ghost' leftIcon={<FaBars />}>
            Menu
          </MenuButton>
          <MenuList>
            {menuButtons.filter(m => m.min_user_type <= user.user_type).map(m => (
              <MenuItem key={m.title+m.link}>
                <Link to={m.link}>
                  <Button
                    as='span'
                    aria-label={m.title}
                    variant='ghost'
                    leftIcon={m.icon}
                  >
                    {m.title}
                  </Button>
                </Link>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Stack>
      <Spacer />
      <Stack direction='row' spacing='2'>
        {userAvatar}
        <Link to='/logout'>
          <IconButton
            aria-label='Contact'
            icon={<FaSignOutAlt />}
          />
        </Link>
        <ColorModeSwitcher />
      </Stack>
    </Flex>
  }

  return <Flex align='center' p='.5em' bg={bg} w='100%' style={{position: 'fixed', top: 0, width: '100%', zIndex: '99999'}}>
    <Stack direction='row'>
      {menuButtons.filter(m => m.min_user_type <= user.user_type).map(m => (
        <Link to={m.link} key={m.title+m.link}>
          <Button
            as='span'
            aria-label={m.title}
            variant={location.pathname === m.link ? 'solid': 'ghost'}
            colorScheme={location.pathname === m.link ? 'teal': 'black'}
            leftIcon={m.icon}
          >
            {m.title}
          </Button>
        </Link>
      ))}
    </Stack>
    <Spacer />
    <Stack direction='row' spacing='4'>
      {userAvatar}
      <Link to='/logout'>
        <IconButton
          aria-label='Contact'
          icon={<FaSignOutAlt />}
        />
      </Link>
      <ColorModeSwitcher />
    </Stack>
  </Flex>
}

export default Nav
