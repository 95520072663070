import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Grid,
  GridItem,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Input,
  Select,
  ButtonGroup,
  useDisclosure,
  Switch,
  FormControl,
  FormLabel,
  useToast,
  Text,
  Stack,
  IconButton
} from '@chakra-ui/react'
import moment from 'moment'
import { useState } from 'react'

import { 
  FaClipboardList,
  FaPlus,
  FaSyncAlt,
  FaSave,
  FaTimes
} from 'react-icons/fa'
import { useRecoilState } from 'recoil'
import userState from '../userState'
import { createReseller, saveReseller } from '../api'
import utils from '../utils'

const getResellerModel = _ => ({
  username: utils.getRandomString(),
  password: utils.getRandomString(),
  user_type: 1,
  credits: 0,
  max_connections: 1,
  expiration : null
})

const ResellerDetails = ({ initialReseller, isCreate, refresh }) => {
  const toast = useToast()
  const user = useRecoilState(userState)[0]

  const [reseller, setReseller] = useState(initialReseller || getResellerModel())
  const { isOpen, onOpen, onClose } = useDisclosure()
  
  const close = _ => {
    setReseller(initialReseller || getResellerModel())
    onClose()
  }

  const save = async _ => {
    if (isCreate) {
      if (reseller.username.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Username is required',
          status: 'error'
        })
        return
      }
      if (reseller.password.length === 0) {
        toast({
          title: 'Save failed',
          description: 'Password is required',
          status: 'error'
        })
        return
      }
      await createReseller(reseller)
    } else {
      await saveReseller(reseller)
    }
    toast({
      title: 'Save success',
      description: `${reseller.username} saved successfully!`,
      status: 'success'
    })
    refresh()
    onClose()
  }

  return <>
    <Button onClick={onOpen}
      size={isCreate ? 'md' : 'sm'}
      colorScheme={isCreate ? 'green' : 'blue'}
      leftIcon={isCreate ? <FaPlus /> : <FaClipboardList />}
      style={{ width: 'fit-content' }}>
        {isCreate ? 'Create' : 'Details'}
    </Button>
    <Modal isOpen={isOpen} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent style={{maxWidth:'1024px'}}>
        <ModalHeader>{ isCreate ? 'Create' : 'Update'} reseller</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns='repeat(1, 1fr)' gap={2}>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Username' />
                <Input placeholder='Username' value={reseller.username || ''} onChange={e => setReseller({ ...reseller, username: e.target.value })} />
                <InputRightAddon children='Username'>
                  <IconButton icon={<FaSyncAlt />} variant='ghost' disabled={!isCreate} onClick={_ => setReseller({ ...reseller, username: utils.getRandomString() })} />
                </InputRightAddon>
              </InputGroup>
            </GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Password' />
                <Input placeholder='Password' value={reseller.password || ''} onChange={e => setReseller({ ...reseller, password: e.target.value })} />
                <InputRightAddon children='Password'>
                  <IconButton icon={<FaSyncAlt />} variant='ghost' onClick={_ => setReseller({ ...reseller, password: utils.getRandomString() })} />
                </InputRightAddon>
              </InputGroup>
            </GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Credits' />
                <Input type='number' value={reseller.credits || 0} onChange={e => setReseller({ ...reseller, credits: 1 * e.target.value })} />
              </InputGroup>
            </GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Max Connections' />
                <Input type='number' value={reseller.max_connections || 1} onChange={e => setReseller({ ...reseller, max_connections: 1 * e.target.value })} />
              </InputGroup>
            </GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Type' />
                <Select value={reseller.user_type} onChange={e => setReseller({ ...reseller, user_type: 1 * e.target.value })}>
                  { user.user_type >= 2 && <option value='1'>Reseller</option> }
                  { user.user_type >= 2 && <option value='2'>Reseller (with sub-reseller)</option> }
                  { user.user_type >= 3 && <option value='3'>Admin</option> }
                </Select>
              </InputGroup>
            </GridItem>
            <GridItem>
              <InputGroup>
                <InputLeftAddon w='150px' children='Expiration' />
                <Input type='date' disabled={reseller.expiration === null}
                  value={moment(reseller.expiration || undefined).format('yyyy-MM-DD')}
                  onChange={e => setReseller({ ...reseller, expiration: e.target.value })}
                  min={moment().format('yyyy-MM-DD')} />
                <InputRightAddon>
                  <FormControl display='flex' alignItems='center'>
                    <FormLabel htmlFor='expiration' mb='0'>
                      Unlimited
                    </FormLabel>
                    <Switch id='expiration' isChecked={reseller.expiration === null} onChange={e => setReseller({ ...reseller, expiration: e.target.checked ? null : moment().format('yyyy-MM-DD') })} />
                  </FormControl>                 
                </InputRightAddon>
              </InputGroup>
            </GridItem>
            <GridItem>
              <Stack>               
                <Stack direction='row' spacing='4'>
                  <Button variant='outline' onClick={_ => setReseller({ ...reseller, expiration: moment().add(1, 'month').format('yyyy-MM-DD') })}>1 Month</Button>
                  <Button variant='outline' onClick={_ => setReseller({ ...reseller, expiration: moment().add(3, 'months').format('yyyy-MM-DD') })}>3 Months</Button>
                  <Button variant='outline' onClick={_ => setReseller({ ...reseller, expiration: moment().add(6, 'months').format('yyyy-MM-DD') })}>6 Months</Button>
                  <Button variant='outline' onClick={_ => setReseller({ ...reseller, expiration: moment().add(1, 'year').format('yyyy-MM-DD') })}>1 Year</Button>
                </Stack>
                <Text fontWeight='bold'>
                  Expiration { reseller.expiration ? moment(reseller.expiration).fromNow() : 'Unlimited' }
                </Text>
              </Stack>
            </GridItem>
          </Grid>            
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button leftIcon={<FaTimes />} mr={1} onClick={close}>Close</Button>
            <Button rightIcon={<FaSave />} colorScheme='green' onClick={save}>Save</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
}

export default ResellerDetails
