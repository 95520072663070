import ChannelsTable from '../components/ChannelsTable';
import ChannelDetails from '../components/ChannelDetails';
import Paginator from '../components/Paginator';

import { getChannels } from '../api';
import { useEffect, useState, useCallback } from 'react';
import { Stack } from '@chakra-ui/layout';

function Channels() {
  const [currentPage, setCurrentPage] = useState(0);
  const [channelsData, setChannelsData] = useState({
    channels: [],
    totalChannels: currentPage,
  });

  const refresh = useCallback(page => {
      getChannels(page || currentPage).then(setChannelsData)
    }, [currentPage, setChannelsData]);
  useEffect(_ => {
    refresh(currentPage)
  }, [refresh, currentPage]);

  return (
    <Stack spacing="4">
      <ChannelDetails refresh={refresh} isCreate={true} />
      <ChannelsTable channelsData={channelsData} refresh={refresh} />
      <Paginator
        totalItems={channelsData.totalChannels}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Stack>
  );
}

export default Channels;
