import BouquetsTable from '../components/BouquetsTable' 

import {
  Stack
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { getBouquets } from '../api'

export default function Bouquets () {
  const [bouquets, setBouquets] = useState([])
  
  const refresh = _ => getBouquets().then(setBouquets)
  useEffect(_ => {
    refresh()
  }, [])

  return <Stack spacing='4'>
    <BouquetsTable bouquets={bouquets} refresh={refresh} />
  </Stack>
}
