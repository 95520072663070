import { useEffect, useState } from 'react'
import { 
  Stack
} from '@chakra-ui/react'

import { getCategories } from '../api'

import CategoriesTable from '../components/CategoriesTable'

function Categories () {
  const [categories, setCategories] = useState([])

  const refresh = _ => getCategories().then(setCategories)
  useEffect(_ => {
    refresh()
  }, [])

  return <Stack spacing='4'>
    <CategoriesTable categories={categories} refresh={refresh} />
  </Stack>
}

export default Categories
