import {
  Center,
  Stack,
  ButtonGroup,
  Button,
  IconButton,
  Text
} from '@chakra-ui/react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

function Paginator ({ itemsPerPage = 10, totalItems, currentPage, setCurrentPage }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage) - 1
  const prevPages = [3, 2, 1]
  const nextPages = [1, 2, 3]

  const maxElPerPage = Math.min(totalItems, itemsPerPage)

  if (totalPages === 0) return null

  return <Center>
    <Stack>
      <ButtonGroup isAttached>
        <IconButton size='sm' icon={<FaChevronLeft />} disabled={currentPage <= 0} onClick={_ => setCurrentPage(currentPage - 1)} />
        {
          prevPages.map(p => {
            const newPage = currentPage - p
            if (newPage < 0) return null
            return <Button key={p} size='sm' onClick={_ => setCurrentPage(newPage)}>{newPage + 1}</Button>
          })
        }
        <Button size='sm' colorScheme='teal' onClick={_ => setCurrentPage(currentPage)}>{currentPage + 1}</Button>
        {
          nextPages.map(p => {
            const newPage = currentPage + p
            if (newPage > totalPages) return null
            return <Button key={p} size='sm' onClick={_ => setCurrentPage(newPage)}>{newPage + 1}</Button>
          })
        }
        <IconButton size='sm' icon={<FaChevronRight />} disabled={currentPage >= totalPages} onClick={_ => setCurrentPage(currentPage + 1)} />
      </ButtonGroup>
      <Text align='center' fontSize='sm'>{`${maxElPerPage * currentPage}-${maxElPerPage * (currentPage + 1)}/${totalItems} Pages: ${totalPages + 1}`}</Text>
    </Stack>
  </Center>
}

export default Paginator
